import React, { useRef, useContext, useState } from "react";
import { AppState } from "../../../App";
import axios from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import classes from "./AskQuestionPage.module.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

function AskQuestionPage() {
  const { user, appErrors } = useContext(AppState);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const userid = user.userid;

  const navigate = useNavigate();
  const titleDom = useRef();
  const detailDom = useRef();

  async function handle(e) {
    e.preventDefault();
    // Clear previous errors
    setErrors({});

    const titleValue = titleDom?.current?.value;
    const detailValue = detailDom?.current?.value;

    let hasError = false;
    const newError = {};

    if (!titleValue && !detailValue) {
      newError.title = "Please enter your question";
      hasError = true;
    }
    if (!titleValue) {
      newError.detail = "Please enter the title of your question";
      hasError = true;
    }

    if (!detailValue) {
      newError.detail = "Please enter a description of your question.";
      hasError = true;
    }
    if (hasError) {
      setErrors(newError);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
      await axios.post(
        "/question",
        { userid: userid, title: titleValue, description: detailValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ msg: "Question asked successfully" });
      // Consider delaying navigation or clearing fields before navigating
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    } catch (error) {
      console.error(error.response ? error.response.data.msg : error.message);
    }
    setErrors(appErrors);
  }

  return (
    <>
      <Header />
      <section className={classes.back}>
        <div className={classes.Instruction}>
          <h3 className={classes.step}>Steps To Write A Good Question</h3>
          <span className={classes.line}></span>
        </div>
        <div className={classes.points}>
          <p>
            <span>
              <svg
                stroke="currentColor"
                // fill="currentColor"
                stroke-width="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1.3em"
                width="1.5em"
                fill="#516CF0"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            Summarize your problems in a one-line title.
          </p>
          <p>
            <span>
              <svg
                stroke="currentColor"
                // fill="currentColor"
                stroke-width="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1.3em"
                width="1.5em"
                fill="#516CF0"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            Describe your problem in more detail
          </p>
          <p>
            <span>
              <svg
                stroke="currentColor"
                // fill="currentColor"
                stroke-width="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1.3em"
                width="1.5em"
                fill="#516CF0"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            Describe what you tried and what you expected to happen.
          </p>
          <p>
            <span>
              <svg
                stroke="currentColor"
                // fill="currentColor"
                stroke-width="0"
                viewBox="0 0 20 20"
                aria-hidden="true"
                height="1.3em"
                width="1.5em"
                fill="#516CF0"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            Review your question and post it here.
          </p>
        </div>
        <div className={classes.question_container}>
          <h3 className={classes.post}>Ask a public question</h3>
          <div className={classes.span}>
            <span>Go to Question page</span>
          </div>
          {Object.keys(errors).length > 0 && (
            <div className={classes.server_error}>
              {Object.entries(errors)[0] && (
                <div>{Object.entries(errors)[0][1]}</div> // Display the message of the first error
              )}
            </div>
          )}
          {Object.keys(message).length > 0 && (
            <div className={classes.server_success}>
              {Object.entries(message)[0] && (
                <div>{Object.entries(message)[0][1]}</div> // Display the message of success
              )}
            </div>
          )}
          <form onSubmit={handle}>
            <input
              type="text"
              placeholder="Question title"
              ref={titleDom}
              className={errors.title ? classes.error_title : classes.title}
            />
            <br />
            <input
              type="text"
              placeholder="Question detail..."
              ref={detailDom}
              className={errors.detail ? classes.error_detail : classes.detail}
            />
            <div className={classes.buttonContainer}>
              <button className={classes.sub}>Post Your Question</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AskQuestionPage;
