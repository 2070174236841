import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../App";
import questionerIMG from "../../../assets/user 1.png";
import classes from "./QuestionAndAnswer.module.css";
import { FaArrowCircleRight } from "react-icons/fa";
function QuestionAndAnswer() {
  //to access user from the AppState context.
  const { user, appErrors } = useContext(AppState);

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  const userid = user.userid;

  const navigate = useNavigate();
  //a reference to the textarea element, allowing direct DOM manipulation.
  const answerDom = useRef();
  // Extracts the question ID from the URL parameters.
  const { id } = useParams();

  const [question, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [newAnswer, setNewAnswer] = useState("");

  useEffect(() => {
    //fetch question and answers data.
    async function fetchData() {
      try {
        //Sends a GET request to fetch the question data based on the id from the URL.
        const responseQuestion = await axios.get(`/question/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Updates the question state with the fetched question data.
        setQuestion(responseQuestion.data.question);
        //Sends a POST request to fetch the answers.
        const responseAnswers = await axios.get(`/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnswers(responseAnswers.data.answers);
      } catch (error) {
        console.error("Error fetching question or answers:", error);
      }
    }
    fetchData();
  }, []);
  //Updates the newAnswer state whenever the value of the textarea changes.
  const handleAnswerChange = (e) => {
    setNewAnswer(e.target.value);
  };

  async function handle(e) {
    e.preventDefault();
    const answerValue = answerDom.current.value;
    const newError = {};

    if (!answerValue) {
      newError.answer = "Please provide your answer";
      setErrors(newError);
      return;
    }

    try {
      await axios.post(
        "/answer",
        { userid, question_id: id, answer: answerValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ msg: "Answer replied successfully" });
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
    setErrors(appErrors);
  }

  return (
    <>
      <Header />
      <section className={classes.question_answers_page}>
        <div className={classes.question_answers_Container}>
          <div className={classes.question}>
            <h2>Question</h2>
            <div className={classes.description}>
              {/* <FaArrowCircleRight size={25} className="mx-2" /> */}
              <span>
                <svg
                  stroke="currentColor"
                  // fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  height="2em"
                  width="2em"
                  fill="#516CF0"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
              <div className="my-2 mx-1">
                <h5>{question.title} </h5>
                <span className={classes.line}></span>
              </div>
            </div>
            <p>{question.description}</p>
          </div>
          <div className={classes.answers}>
            <hr />
            <p>Answer From The Community</p>
            <hr />
            {answers.length === 0 ? (
              <p>No answers yet.</p>
            ) : (
              answers.map((answer) => (
                <div key={answer.id} className={classes.answer_item}>
                  <div className={classes.answer_container}>
                    <img
                      src={questionerIMG}
                      alt={`${question.username}'s image`}
                      className={classes.questionerImage}
                      style={{
                        width: "61px",
                        height: "60px",
                        marginLeft: "10px",
                      }}
                    />
                    <p className={classes.answer_username}>{answer.username}</p>
                  </div>
                  <p className={classes.answer_body}>{answer.answer}</p>
                </div>
              ))
            )}
          </div>
          <div className={classes.answer_form}>
            <h2>Answer The Top Question</h2>
            <p>Go to Question page</p>
            {Object.keys(errors).length > 0 && (
              <div className={classes.server_error}>
                {Object.entries(errors)[0] && (
                  <div>{Object.entries(errors)[0][1]}</div> // Display the message of the first error
                )}
              </div>
            )}
            {Object.keys(message).length > 0 && (
              <div className={classes.server_success}>
                {Object.entries(message)[0] && (
                  <div>{Object.entries(message)[0][1]}</div> // Display the message of success
                )}
              </div>
            )}
            <textarea
              className={
                errors.answer ? classes.textarea_error : classes.textarea
              }
              value={newAnswer}
              ref={answerDom}
              onChange={handleAnswerChange}
              placeholder="Your Answer... "
            />
            <button onClick={handle}>Post Your Answer</button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default QuestionAndAnswer;
