import React, { useContext } from "react";
import classes from "./Header.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AppState } from "../../App";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

function Header() {
  const { isLoggedIn, logout } = useContext(AppState);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
   return (
     <Navbar
       key="lg"
       expand="lg"
       fixed="top"
       className="shadow-sm bg-body-tertiary py-3 px-3"
     >
       <Container fluid>
         <Navbar.Brand href={isLoggedIn ? "/home" : "/login"}>
           <img
             src="https://forum.ibrodev.com/assets/evangadi-logo-5fea54cc.png"
             alt="evangadi-form"
             className={classes.logo}
           />
         </Navbar.Brand>
         <Navbar.Toggle aria-controls="offcanvasNavbar" />
         <Navbar.Offcanvas
           id="offcanvasNavbar"
           aria-labelledby="offcanvasNavbarLabel"
           placement="end"
         >
           <Offcanvas.Header closeButton>
             <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
           </Offcanvas.Header>
           <Offcanvas.Body>
             <Nav className={`justify-content-end flex-grow-1 ${classes.Nav}`}>
               <Nav.Link
                 as={Link}
                 to={isLoggedIn ? "/home" : "/login"}
                 className={classes.Nav_Link}
               >
                 Home
               </Nav.Link>
               <Nav.Link
                 as={Link}
                 to={isLoggedIn ? "/home" : "/login"}
                 className={classes.Nav_Link}
               >
                 How it works
               </Nav.Link>
               {isLoggedIn ? (
                 <Button
                   variant="outline-danger"
                   onClick={handleLogout}
                 >
                   Logout
                 </Button>
               ) : (
                 <Button variant="outline-primary" as={Link} to="/login">
                   Sign In
                 </Button>
               )}
             </Nav>
           </Offcanvas.Body>
         </Navbar.Offcanvas>
       </Container>
     </Navbar>
   );

  }
  export default Header;
